import {
  faHandPointLeft,
  faHandPointRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup } from "react-bootstrap";

//for input text
export const handleChange = (e, setFormData) => {
  const { name, value } = e.target;

  if (name === "phone_number" || name === "phone_no") {
    const numericValue = value.replace(/\D/g, "");
    const truncatedValue = numericValue.slice(0, 10);

    setFormData((prevData) => ({
      ...prevData,
      [name]: truncatedValue,
    }));
  } else if (name === "zip_code") {
    const numericValue = value.replace(/\D/g, "");
    const truncatedValue = numericValue.slice(0, 5);

    setFormData((prevData) => ({
      ...prevData,
      [name]: truncatedValue,
    }));
  } else {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value || "",
    }));
  }
};

// React select forms
export const valuehandler = (arr, value) =>
  arr?.find((opt) => opt.value === value);

export const multiValueHandler = (optionArr, valueArr) => {
  let selectedArr = [];
  valueArr?.forEach((value) => {
    const selectedValue = optionArr.find((opt) => opt.value === value);
    if (selectedValue) selectedArr.push(selectedValue);
  });

  return selectedArr;
};

//options
export const yesNoOptions = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 },
];

//Search Filter
export const handleSearchChange = (event, arr, state_name) => {
  const searchTerm = event.target.value.toLowerCase();

  // Filter the data based on the user's input
  const filteredResult = arr.filter((item) =>
    item[state_name].toLowerCase().includes(searchTerm)
  );
  return filteredResult;
};

// Functions to scroll the table container to the left and right
export const scrollLeft = (tableContainerRef, scrollValue) => {
  if (tableContainerRef.current) {
    tableContainerRef.current.scrollBy({
      left: -scrollValue,
      behavior: "smooth",
    });
  }
};

export const scrollRight = (tableContainerRef, scrollValue) => {
  if (tableContainerRef.current) {
    tableContainerRef.current.scrollBy({
      left: scrollValue,
      behavior: "smooth",
    });
  }
};

export const scrollButton = (tableContainerRef, scrollValue) => {
  return (
    <div className="d-flex justify-content-end">
      <ButtonGroup>
        <Button
          variant="link"
          onClick={() => scrollLeft(tableContainerRef, scrollValue)}
        >
          <FontAwesomeIcon
            icon={faHandPointLeft}
            className="fs-3 green-color"
          />
        </Button>
        <Button
          variant="link"
          onClick={() => scrollRight(tableContainerRef, scrollValue)}
          className="fs-3 green-color"
        >
          <FontAwesomeIcon icon={faHandPointRight} />
        </Button>
      </ButtonGroup>
    </div>
  );
};
