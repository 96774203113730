import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/img/logo.png";
import FooterCard from "../../../reusable/Cards/FooterCard";
import { Routes } from "../../../routes";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  const currentYear = moment().get("year");
  const data = useSelector((state) => state?.homePage.dispByState);
  const userData = useSelector((state) => state.login.userData);

  // Memoized list of top 10 dispensaries based on count
  const statesArr = useMemo(() => {
    if (!data?.length) return [];
    return (
      data
        // .slice()
        // .sort((a, b) => b.dispensaries_count - a.dispensaries_count)
        // .slice(0, 10)
        .map((item) => ({
          name: item.state_name,
          lat: Number(item.latitude),
          long: Number(item.longitude),
          link: `${
            Routes.Explore.path
          }/Storefronts/state=${item.state_name.replaceAll(" ", "-")}`,
        }))
    );
  }, [data]);

  // Menu items
  const menuArr = useMemo(
    () => [
      {
        state: "Storefronts",
        link: `${Routes.Explore.path}/Storefronts`,
      },
      {
        state: "Deliveries",
        link: `${Routes.Explore.path}/Deliveries`,
      },
      {
        state: "Store & Delivery",
        link: `${Routes.Explore.path}/Store&Deliveries`,
      },
      { state: "Blogs", link: `${Routes.blogs.path}` },
    ],
    []
  );

  // Quick Links
  const quickLinkArr = useMemo(
    () => [
      {
        state: "Terms of use",
        link: Routes.termsOfUSe.path,
      },
      {
        state: "Privacy Policy",
        link: Routes.PrivacyPolicy.path,
      },
      {
        state: "FAQ",
        link: Routes.faqPage.path,
      },
    ],
    []
  );

  // Contact Information
  const contactArr = useMemo(
    () => [
      {
        state: "contact@weedtome.com",
        icon: faEnvelope,
        link: "#",
        preState: "mailto",
      },
      { state: "(888) 988-8420", icon: faPhoneAlt, link: "#", preState: "tel" },
    ],
    []
  );
  // const clickHandler = (e, cardText) => {
  //   window.location.href = `${cardText.preState}:${cardText.state}`;
  //   e.preventDefault();
  // };
  return (
    <div>
      <hr className="top-line m-0" />

      <Row className="m-5 mb-2">
        <Col className="mb-4 pe-5" lg={3} md={5} sm={12}>
          <Link to={Routes.Home.path}>
            <Image src={logo} alt="logo" width={300} />
          </Link>
          <p className="text-align-center mt-4 fontweigh-500 fs-7">
            An inclusive community assisting medical and recreational marijuana
            lovers in discovering top-notch dispensaries nearby for purchasing
            their favorite products.
          </p>
        </Col>
        {/* <Col xs={2}></Col> */}

        {/* <Col className="mb-6" lg={2} md={4} sm={6}>
          <FooterCard cardTitle="Dispensaries In" cardTextArr={statesArr} />
        </Col> */}
        <Col className="mb-6" xl={2} lg={2} md={3} sm={6} xs={6}>
          <FooterCard cardTitle="Listing Menu" cardTextArr={menuArr} />
        </Col>
        <Col className="mb-6" xl={2} lg={2} md={3} sm={6} xs={6}>
          <FooterCard cardTitle="Quick Links" cardTextArr={quickLinkArr} />
        </Col>
        <Col className="mb-6" xl={2} lg={3} md={5} sm={6} xs={8}>
          <FooterCard cardTitle="Contact Us" cardTextArr={contactArr} />
        </Col>
        <Col xl={3} lg={2} md={4} sm={4} xs={12}>
          <div
            className="bg-dark rounded p-3 text-white"
            style={{ marginTop: "-1.5rem" }}
          >
            <h2 className="fs-5 fw-bolder">For Business</h2>
            <Link
              className="fs-7"
              to={{
                pathname: userData.plan_id
                  ? Routes.buyPlan.path
                  : userData.role === "partner"
                  ? userData.state
                    ? Routes.buyPlan.path
                    : Routes.MyProfile.path
                  : Routes.Signup.path,
                state: { type: "partner" },
              }}
            >
              Get started
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="mx-5 mb-4">
        <h4 className="mb-3">Dispensaries In</h4>
        {statesArr.map((state) => (
          <Col className="mb-2">
            <Link
              className="fontweigh-500 fs-7 link-text"
              to={{
                pathname: state.link,
                state: {
                  type: state?.type,
                  lat: state?.lat,
                  long: state?.long,
                },
              }}
              // onClick={(e) => cardText.link === "#" && clickHandler(e, cardText)}
            >
              <FontAwesomeIcon icon={state.icon} /> {state.name}
            </Link>
          </Col>
        ))}
      </Row>
      <Row className="justify-content-center bg-dark text-light p-2 m-0 w-100">
        Copyright © {`WeedToMe ${currentYear} | All Rights Reserved`}
      </Row>
    </div>
  );
};

export default Footer;
