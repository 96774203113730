import { Button, Card, CardBody, Col, Image, Row } from "react-bootstrap";
import { Routes } from "../routes";
import { Link } from "react-router-dom";
import verifiedBadge from "../assets/img/verifiedBadge.webp";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "../axios";
import { useDispatch, useSelector } from "react-redux";
import { loadingSet } from "../redux/loadingSlice";
import "./DispensaryCard.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DispensaryClinicCard = ({
  data,
  type,
  refetchData,
  setShowToast,
  setToastBody,
  setSuccess,
}) => {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.login.userData);
  const history = useHistory();

  if (!data) {
    return;
  }
  const today = moment().format("dddd").toLowerCase();
  const workHours = data?.work_hours && data.work_hours[today];
  let workTimings;
  let openTimingsText;
  if (workHours?.includes("-")) {
    workTimings = workHours.split("-")[1];
    openTimingsText = (
      <div className="mx-2">
        <span className="green-status "></span>{" "}
        <p className="fs-8 m-0 d-inline fw-bold text-muted">
          Open until {today} at {moment(workTimings, "HH:mm").format("hh:mm A")}
        </p>
      </div>
    );
  } else {
    workTimings = workHours?.replaceAll("_", " ");
    openTimingsText = workTimings ? (
      <div className="mx-2">
        <span
          className={`${
            workTimings.includes("open")
              ? "green-status"
              : workTimings.includes("closed")
              ? "red-status"
              : "brown-status"
          }`}
        ></span>{" "}
        <p className="fs-8 m-0 d-inline text-muted">
          {workTimings} for {today}
        </p>
      </div>
    ) : (
      ""
    );
  }
  const handleToggleFav = async (id, is_favorite) => {
    if (!role) {
      history.push({
        pathname: Routes.Signin.path,
        state: { type: "dispensary_card" },
      });
      return;
    }
    dispatch(loadingSet(true));
    try {
      let res;
      if (is_favorite) {
        res = await axios({
          method: "DELETE",
          url: `/remove/wishlist/dispensary/${id}`,
        });
      } else {
        res = await axios({
          method: "POST",
          url: `/add/wishlist/dispensary`,
          data: { dispensary_id: id },
        });
      }
      refetchData();

      setShowToast(true);
      setToastBody(res.data.message);
      setSuccess(true);
    } catch (err) {
      setShowToast(true);
      setToastBody(err.response.data.message);
      setSuccess(false);
    } finally {
      dispatch(loadingSet(false));
    }
  };
  return (
    <Card key={data.id} className="image-card">
      <Link
        to={`${
          type === "clinics"
            ? Routes.clinicDetails.path
            : Routes.dispensaryDetails.path
        }/${data.id}/${data.name.replaceAll(" ", "-")}/profile`}
        className="text-decoration-none"
      >
        <CardBody
          className="disp-image rounded-top p-0"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${data.cover_image})`,
          }}
        >
          <Row className=" mx-1">
            <Col xs={8}>
              <p
                className="bg-dark text-white px-1"
                style={{ width: "fit-content" }}
              >
                {data.is_medical ? "MED" : ""}{" "}
                {data.is_medical && data.is_recreational ? "&" : ""}{" "}
                {data.is_recreational ? "REC" : ""}
              </p>
            </Col>
          </Row>
          <Row className="content-left d-flex">
            <Col xs={3}>
              {data?.logo && (
                <Image
                  src={data.logo}
                  alt="Logo"
                  height={50}
                  width={50}
                  style={{
                    borderRadius: "80%",
                    // height: "100%",
                    // width: "100%",
                  }}
                />
              )}
            </Col>
          </Row>
        </CardBody>
        <Col className="p-0 mt-2 rounded-bottom text-dark fontweigh-500 fs-7">
          <div className=" mx-2 mb-0 d-flex align-items-center">
            {data.name}{" "}
            {data.plan_id > 1 && (
              <Image
                alt="verified badge"
                // className="mb-4"
                src={verifiedBadge}
                width={35}
                height={30}
              />
            )}{" "}
          </div>

          {openTimingsText}
          {data?.distance ? (
            <p className="mx-2 fs-8">
              <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
              {data?.distance?.toFixed(2)} miles away
            </p>
          ) : (
            ""
          )}
        </Col>
      </Link>
      {(!role || role === "user") && type !== "clinics" && (
        <Button
          variant="link"
          onClick={() => handleToggleFav(data.id, data.favourite_dispensary)}
          className="fav-btn"
        >
          {" "}
          <FontAwesomeIcon
            icon={faHeart}
            color={data.favourite_dispensary ? "#ff9f89db" : "#fff"}
            className="fs-6"
          />
        </Button>
      )}
    </Card>
  );
};
export default DispensaryClinicCard;
